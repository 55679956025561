import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="home-one body-box">
      <Header />
      <section className="banner v1">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 m-auto">
              <div className="ban-content text-center">
                <h1>
                  Transfer Money Across the World <br />
                  With No Charge
                </h1>
                <p>
                  Transfer money across the globe effortlessly and without any
                  charges. Say goodbye to hidden fees and unexpected charges –
                  with us, your funds reach their destination without any
                  additional costs. Experience the freedom of sending money
                  across the world with zero fees attached.
                </p>
                <Link to="/send-money" className="btn btn-default">
                  <span className="bh" /> <span>Send Money</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="easy-steps">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10 m-auto">
              <div className="sec-heading">
                <h2 className="sec-title">Easy and Fast Way to Send Money</h2>
                <p className="sec-subtitle">
                  Experience the quickest and simplest way to send money. With
                  our platform, transferring funds is as easy as a few taps on
                  your device. Say goodbye to lengthy processes and waiting
                  times – enjoy instant transfers to your loved ones, anytime,
                  anywhere.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10 col-md-12 m-auto">
              <ul className="steps">
                <li>
                  <span>1</span> Select Country
                </li>
                <li>
                  <span>2</span> Choose Service
                </li>
                <li>
                  <span>3</span> Input Information
                </li>
                <li>
                  <span>4</span> Confirm and Send
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="features">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 col-md-9 m-auto">
              <div className="sec-heading text-center">
                <h2 className="sec-title">We Bring Everything In One Place</h2>
                <p className="sec-subtitle">
                  Discover convenience like never before with our all-in-one
                  platform. We bring everything you need together in one place,
                  simplifying your financial tasks.
                </p>
              </div>
            </div>
          </div>
          <div className="features-scrns">
            <figure className="scrn-1">
              <img src="images/scrn-1.png" alt="" />
            </figure>
            <figure className="scrn-2">
              <img src="images/scrn-2.png" alt="" />
            </figure>
            <figure className="scrn-3">
              <img src="images/scrn-3.png" alt="" />
            </figure>
          </div>
        </div>
      </section>

      <section className="ps-works">
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="content-box-fluid right">
                <h2>How Does Our Payment System Work?</h2>
                <p>
                  Our payment system operates seamlessly to ensure effortless
                  transactions. When you're ready to send money, simply select
                  your recipient details, specify the amount and hit send. Our
                  intuitive platform handles the rest, swiftly transferring your
                  funds while prioritizing security and reliability.
                </p>
                <p>
                  Rest assured, our system is designed with transparency and
                  efficiency in mind. Whether you're sending money domestically
                  or across borders, trust our payment system to streamline your
                  financial interactions, making every transfer a breeze.
                </p>
                <Link to="/about" className="btn btn-default">
                  <span className="bh" /> <span>Learn More</span>
                </Link>
              </div>
            </div>
            <div className="col-md-6">
              <figure className="video-box">
                <img src="images/video-bg/1.jpg" alt="work" />
              </figure>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Home;
