import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const About = () => {
  return (
    <div className="home-one">
      <Header />
      <section className="banner v7">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-9">
              <div className="ban-content">
                <h1>First And Easy Money Transfer Solution Across World</h1>
                <p>
                  Introducing the ultimate solution for hassle-free global money
                  transfers. With a user-friendly interface and swift
                  processing, sending money across borders has never been
                  easier. Whether you're supporting family abroad or conducting
                  business overseas, trust our platform to simplify your
                  transactions. Join the thousands who've already chosen us for
                  their first and easiest money transfer solution.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="system-works bg-offwhite">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tab-content">
                <div
                  id="money-transition"
                  className="tab-pane fade in active show"
                >
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <figure className="imgBox">
                        <img src="images/payment-service-3.png" alt="" />
                      </figure>
                    </div>
                    <div className="col-md-6">
                      <div className="content-box">
                        <h2>
                          We Made The Easiest Possible Way To Transfer Money
                        </h2>
                        <p>
                          Discover the simplicity and efficiency of our
                          comprehensive money transfer services. We've got you
                          covered every step of the way. Seamlessly send money
                          across borders with our user-friendly platform,
                          ensuring your funds reach their destination promptly
                          and securely.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default About;
