import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const sendMessage = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      await axios.post("https://app.cathaynational.com/api/message", {
        name,
        email,
        message,
      });

      setLoading(false);
      toast.success("Message sent");
      setName("");
      setEmail("");
      setMessage("");
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="contact-page">
      <Header />
      <section className="banner">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <div className="ban-content text-center">
                <h1>Contact Information</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 nopadd-rth">
              <div className="contact-from-wrap">
                <div className="sec-heading">
                  <h2 className="sec-title">Get In Touch</h2>
                </div>
                <form onSubmit={sendMessage} className="contact-form clearfix">
                  <div className="form-group row align-items-end">
                    <div className="col-sm-12 mb-4 mb-sm-0">
                      <label htmlFor="name">Full name</label>
                      <input
                        type="text"
                        id="name"
                        className="form-control"
                        placeholder="Full name"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email address</label>
                    <input
                      type="email"
                      id="email"
                      className="form-control"
                      placeholder="Your email"
                      value={email}
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="message">Write message</label>
                    <textarea
                      id="message"
                      className="form-control"
                      placeholder="Your messege"
                      value={message}
                      required
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                  {loading ? (
                    <button
                      type="button"
                      className="btn btn-filled btn-round"
                      disabled
                    >
                      <span className="bh" /> <span>Sending...</span>
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-filled btn-round">
                      <span className="bh" /> <span>Send</span>
                    </button>
                  )}
                </form>
              </div>
            </div>
            <div className="col-md-5 nopadd-ltf">
              <div className="contact-info-wrap">
                <address>
                  <i className="fas fa-envelope" />
                  <span>Email Address</span>
                  <a href="mailto:support@cathaynational.com">
                    support@cathaynational.com
                  </a>
                </address>
                <address>
                  <i className="fas fa-phone-alt" />
                  <span>Phone Number</span>
                  <a href="#/">+1 (970) 316‑4537</a>
                </address>
                <address>
                  <i className="fas fa-map-marker-alt" />
                  <span>Our Head Office</span>
                  705 N Mountain Rd Newington, Connecticut(CT), 06111
                </address>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-map">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10 m-auto text-center">
              <div className="sec-heading">
                <h2 className="sec-title">
                  We Are Available All Over The World
                </h2>
                <p className="sec-subtitle">
                  Experience the global reach of our seamless money transfer
                  services, connecting you to over 100 countries worldwide. No
                  matter where you are or where your recipient is located, our
                  platform ensures accessibility and convenience at your
                  fingertips.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div id="map">
                <img src="images/map.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />

      <ToastContainer hideProgressBar autoClose={3000} closeButton={false} />
    </div>
  );
};

export default Contact;
