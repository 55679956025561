import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

const SendMoney = () => {
  return (
    <div className="home-one">
      <Header />
      <section className="banner v6">
        <div className="container">
          <div className="row">
            <div className="col-md-11 m-auto">
              <div className="ban-content text-center">
                <h1>
                  Introducing a New Way of Sending Money,
                  <br /> All Over The World
                </h1>
                <p>
                  Introducing a groundbreaking way to send money globally: our
                  innovative platform revolutionizes international transfers.
                  Say goodbye to traditional methods and hello to effortless
                  transactions across the world. Experience the future of money
                  transfer with us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="easy-steps">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-10 m-auto">
              <div className="sec-heading">
                <h2 className="sec-title">Easy and Fast Way to Send Money</h2>
                <p className="sec-subtitle">
                  Experience the quickest and simplest way to send money. With
                  our platform, transferring funds is as easy as a few taps on
                  your device. Say goodbye to lengthy processes and waiting
                  times – enjoy instant transfers to your loved ones, anytime,
                  anywhere.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10 col-md-12 m-auto">
              <ul className="steps">
                <li>
                  <span>1</span> Select Country
                </li>
                <li>
                  <span>2</span> Choose Service
                </li>
                <li>
                  <span>3</span> Input Information
                </li>
                <li>
                  <span>4</span> Confirm and Send
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="deposit-money bg-offwhite">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="content-box">
                <span className="tagline bar">Bank Transfer</span>
                <h2>Transfer Money to Bank</h2>
                <p>
                  Easily transfer money directly into your bank account with our
                  streamlined service. Experience the convenience of seamless
                  transactions, putting your money exactly where you need it,
                  when you need it.
                </p>
              </div>
              <div className="accordion has-numbering" id="accordion2">
                <div className="accordion-item active">
                  <h5
                    className="collapsed"
                    data-toggle="collapse"
                    data-target="#dm-1"
                  >
                    Choose Your Country
                  </h5>
                  <div
                    id="dm-1"
                    className="collapse show"
                    data-parent="#accordion2"
                  ></div>
                </div>
                <div className="accordion-item">
                  <h5
                    className="collapsed"
                    data-toggle="collapse"
                    data-target="#dm-2"
                  >
                    Add Bank Information
                  </h5>
                  <div
                    id="dm-2"
                    className="collapse"
                    data-parent="#accordion2"
                  ></div>
                </div>
                <div className="accordion-item">
                  <h5
                    className="collapsed"
                    data-toggle="collapse"
                    data-target="#dm-3"
                  >
                    Confirm information and Complete Transfer
                  </h5>
                  <div
                    id="dm-3"
                    className="collapse"
                    data-parent="#accordion2"
                  ></div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <figure className="imgBox">
                <img src="images/deposit-map.png" alt="" />
              </figure>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default SendMoney;
