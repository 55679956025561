import React from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";

const NotFound = () => {
  return (
    <div className="error-page">
      <Header />
      <section className="error-section">
        <div className="container">
          <div className="row">
            <div className="col-md-11 m-auto">
              <img src="images/404.png" alt="" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-9 m-auto text-center">
              <div className="sec-heading">
                <h2 className="sec-title">Oops! Page Not Found</h2>
                <p className="sec-subtitle">
                  The page you are looking for has either been deleted or never
                  existed.
                </p>
              </div>
              <Link to="/" className="btn btn-filled btn-round">
                <span className="bh" /> <span>Back to Home</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default NotFound;
