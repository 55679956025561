import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoMenu } from "react-icons/io5";
import logo from "../assets/logo.png";

const Header = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <header className="header">
      <div className="container d-flex align-items-center">
        <Link className="logo" to="/">
          <img src={logo} alt="logo" />
        </Link>
        <nav className="primary-menu text-md-right">
          <a id="mobile-menu-toggler" href="#/" onClick={toggleDropdown}>
            <IoMenu />
          </a>
          <ul style={dropdownOpen ? { display: "block" } : { display: "none" }}>
            <li className="current-menu-item has-menu-child">
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/send-money">Send Money</Link>
            </li>
            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <a
                href="https://dashboard.cathaynational.com"
                className="login btn btn-outline btn-round"
              >
                <span className="bh" /> <span>Dashboard</span>
              </a>
            </li>
          </ul>
          <ul>
            <li className="current-menu-item has-menu-child">
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/send-money">Send Money</Link>
            </li>

            <li>
              <Link to="/about">About</Link>
            </li>
            <li>
              <Link to="/contact">Contact</Link>
            </li>
            <li>
              <a
                href="https://dashboard.cathaynational.com"
                className="login btn btn-outline btn-round"
              >
                <span className="bh" /> <span>Dashboard</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
